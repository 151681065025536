import React from 'react';
import './App.scss';
import { Routes, Route,} from 'react-router-dom';
import Home from '../pages/Home';
import Layout from '../pages/Layout';
import Privacy from '../pages/Privacy';
import ForShortPost1 from '../../components/blog/fullversionBlogPages/ForShortPost1';
import ForShortPost2 from '../../components/blog/fullversionBlogPages/ForShortPost2'
import ForShortPost3 from '../../components/blog/fullversionBlogPages/ForShortPost3';
import ForShortPost4 from '../../components/blog/fullversionBlogPages/ForShortPost4';
import ForShortPost5 from '../../components/blog/fullversionBlogPages/ForShortPost5';
import ForShortPost6 from '../../components/blog/fullversionBlogPages/ForShortPost6';
import ForShortPost7 from '../../components/blog/fullversionBlogPages/ForShortPost7';
import CookiesPopUp from '../../components/cookiesPopup/CookiesPopUp';
import { useState,useEffect } from 'react';
import Modal from '../../components/modal/Modal';
import Success from '../../components/success-page/Success';

function App() {

  const [popUpState, setPopUpState] = useState(false);
  useEffect(() => {
    localStorage.getItem("p_up_state") === "true"
      ? setPopUpState(false)
      : setPopUpState(true);
  }, []);
  const onClickPopUpAgreeButton = () => {
    localStorage.setItem("p_up_state", "true");
    setPopUpState(false);
  };
  const onClickPopUpDenyButton = () => {
    localStorage.setItem("p_up_state", "false");
    setPopUpState(false);
  };

  const [modalActive, setModalActive] = useState(false);

  const handlerSelectModal = () => {
    setModalActive(!modalActive);
  };

  let modalContent = <Success />;

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={
           <React.Fragment>
           {popUpState && (<CookiesPopUp onClickPopUpAgreeButton={onClickPopUpAgreeButton} onClickPopUpDenyButton={onClickPopUpDenyButton}/>)} {/*languageSelect тут и он передается везде */}
        <Layout />
        </React.Fragment>
        }>
          <Route index element={<Home handlerSelectModal={setModalActive} />} />
          <Route path='privacy' element={<Privacy/>}/>
          <Route path='post/1' element={<ForShortPost1/>}/>
          <Route path='post/2' element={<ForShortPost2/>}/>
          <Route path='post/3' element={<ForShortPost3/>}/>
          <Route path='post/4' element={<ForShortPost4/>}/>
          <Route path='post/5' element={<ForShortPost5/>}/>
          <Route path='post/6' element={<ForShortPost6/>}/>
          <Route path='post/7' element={<ForShortPost7/>}/>
        </Route>
      </Routes>
      <Modal  setActive={handlerSelectModal} active={modalActive}>
          {modalContent}
        </Modal>
    </div>
  );
}

export default App;
