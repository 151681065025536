import React from 'react';
import './Header.scss';
import logo from '../../assets/img/Logo.svg';
import arrowDown from '../../assets/img/arrowDown.svg';
import useOutsideAlerter from '../additional-components/useOutsideAlerter';
import HeaderMob from './header-mob/HeaderMob';
import { HashLink} from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { useState,useEffect } from 'react';

function Header() {

  const [refLeft,isShowLeft,setIsShowLeft] = useOutsideAlerter(false);
  const [refRight,isShowRight,setIsShowRight] = useOutsideAlerter(false);

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

 

  return (
    <>
      <div className={`header-main ${!isHeaderVisible ? 'hidden' : ''}`}
  >
      <div className="header-content">
        <div className="header-nav-left">
          <div><HashLink to={'/#Onas'} smooth>O nas</HashLink></div>
          <div><HashLink to={'/#cel'} smooth>Nasz cel</HashLink></div>
          <div ref={refLeft} className='drop-menu' onClick={() => setIsShowLeft(!isShowLeft)}>
            <div className='drop-links'>News</div>
            <div className='arrow-down'><img src={arrowDown} alt="arrowDown" /></div>
            {isShowLeft && (
                <div className="drop-down">
                <div className="drop-down-item"><HashLink to={'/#WiedzaIporady'} smooth>Wiedza i porady</HashLink></div>
                <div className="drop-down-item"><HashLink to={'/#WydarzeniaIwebinary'} smooth>Wydarzenia i webinary</HashLink></div>
                <div className="drop-down-item"><HashLink to={'/#outsourcingwPolsce'} smooth>#outsourcingwPolsce</HashLink></div>
            </div>
            )}
            
          </div>
        </div>
        <div className="header-logo">
          <div className="logo-image">
            <Link to='/'><img src={logo} alt="logo" /></Link>
          </div>
        </div>
        <div className="header-nav-right">
          <div><HashLink to={'/#partnerzy'} smooth>Nasi partnerzy</HashLink></div>
          <div ref ={refRight} className='drop-menu' onClick={() => setIsShowRight(!isShowRight)}>
            <div className='drop-links'>Członkonstwo</div>
            <div className='arrow-down'><img src={arrowDown} alt="arrowDown" /></div>
            {isShowRight && (
                <div className="drop-down">
                <div className="drop-down-item"><HashLink to={'/#Korzyści'} smooth>Korzyści z członkostwa</HashLink></div>
                <div className="drop-down-item"><HashLink to={'/#сzłonkowie'} smooth>Członkowie</HashLink></div>
                <div className="drop-down-item"><HashLink to={'/#form'} smooth>Deklaracja członkostwa</HashLink></div>
            </div>
            
            )}
          </div>
          <div ><HashLink to={'/#kontakt'} smooth>Kontakt</HashLink></div>
        </div>
      </div>
    </div>
      <HeaderMob/>
    </>
    
  );
}

export default Header;