import React from 'react';
import './Blog.scss';
import { useNavigate } from 'react-router-dom';
import blogShort1 from '../../assets/img/blogShort1.png';
import blogShort2 from '../../assets/img/blogShort2.png';
import blogShort3 from '../../assets/img/blogShort3.png';
import blogShort4 from '../../assets/img/blogShort4.png';
import blogShort5 from '../../assets/img/blogShort5.png';
import Slider from 'react-slick';


function Blog() {



  const navigate = useNavigate();
  const handleClickPostFull = (id) => {
    navigate(`/post/${id}`);
  };
  
  const shortBlogData = [
    // {
    //   id:6,
    //   img:blogShort7,
    //   date:' ',
    //   capture:'21 maja w Bydgoszczy odbędzie się Konferencja Biznes i Nauka',
    //   description:'Kompetencje przyszłości w dobie AI, której głównym partnerem jest Polskie Stowarzyszenie Outsourcingu...',
    // },
    // {
    //   id:7,
    //   img:blogShort6,
    //   date:' ',
    //   capture:'Już 17 maja w Gdańsku odbędzie się Ogólnopolska Konferencja Prawa Karnego Gospodarczego',
    //   description:'Której głównym partnerem jest Polskie Stowarzyszenie Outsourcingu....',
    // },
    {
      id:1,
      img:blogShort1,
      date:' ',
      capture:'Jak przygotować się do wdrożenia outsourcingu w firmie?',
      description:'Outsourcing, czyli wydzielenie i przekazanie części zadań, procesów lub funkcji zewnętrznemu pod...',
    },
    {
      id:2,
      img:blogShort2,
      date:' ',
      capture:'7 korzyści wynikających ze współpracy z firmą outsourcingową',
      description:'Optymalizacja działań przedsiębiorstwa stała się kluczowym wyzwaniem dla współczesnych organ...',
    },
    {
      id:3,
      img:blogShort3,
      date:' ',
      capture:'Outsourcing i praca tymczasowa, które rozwiązanie jest bardziej opłacalne?',
      description:'Dyskusja o wyższości jednej koncepcji współpracy z dostawcami usług nad drugą toczy się nieprzer...',
    },
    {
      id:4,
      img:blogShort4,
      date:' ',
      capture:'Outsourcing procesów biznesowych: Jakie zadania można powierzyć zewnętrznym dostawcom?',
      description:'Sektor usług outsourcingowych w Polsce stale się rozwija. Rośnie także liczba firm zainteresowany...',
    },
    {
      id:5,
      img:blogShort5,
      date:' ',
      capture:'Kompendium wiedzy HR. Praca tymczasowa i outsourcing. Różnice, wady i zalety.',
      description:'Wiele przedsiębiorstw, stojąc przed wyborem dostawcy rozwiązań personalnych, zastanawia si...',
    },
  ];

  let itemLength = shortBlogData.length;
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: itemLength === 1 ? 1 : (itemLength === 2 ? 1 : 3),
    slidesToScroll: itemLength === 1 ? 1 : (itemLength === 2 ? 1 : 3),
    rows: (itemLength % 6 === 0) ? 2 : 1,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          rows: 1
        }
      },
      {
        breakpoint: 1255,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 710,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const blogShortItem = shortBlogData.map((item) => (
    <div  key={item.id}>
      <div className="blog-card">
        <div className="blog-card-content">
          <div className="blog-image">
            <img src={item.img} alt="" />
            
          </div>
          <div className="blog-date">
            {item.date}
          </div>
          <div className="short-blog-capture">
            {item.capture}
          </div>
          <div className="short-blog-description">
            {item.description}
          </div>
          <div className="link-to-full-log-page" onClick={() => {
            handleClickPostFull(item.id)
          }}>
           Czytaj
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <div id={'WiedzaIporady'} className="blog-main">
      <div className="blog-content">
        <div className="blog-caprute-blog">
          <div className="blog-sub-capture">
            news
          </div>
          <div className="blog-capture">
            Wiedza i porady
          </div>
        </div>
        <div className="blog-slider-block">
        <Slider {...settings}>
        {blogShortItem}
        </Slider>
        </div>
      </div>
    </div>
  );
}

export default Blog;