import React from 'react';
import './Privacy.scss';



function Privacy() {

    return (
        <div className="privacy-wrapper">
            <div className="privacy-content">
                <h1>Polityka Prywatności</h1>
                <h3>Spis treści</h3>
                <ul className='ul-nav'>
                    <li><strong>W skrócie:</strong></li>
                    <li><strong>I. Słowniczek - podstawowe pojęcia</strong></li>
                    <li><strong>II. Administrator</strong></li>
                    <li><strong>III. Jakie dane osobowe gromadzimy oraz jaki jest cel i podstawa prawna naszych działań?</strong></li>
                    <li><strong>IV. Jak długo przetwarzamy Twoje dane?</strong></li>
                    <li><strong>V. Kto faktycznie ma dostęp do danych osobowych użytkownika?</strong></li>
                    <li><strong>VI. Czym są pliki cookie i inne podobne technologie? W jaki sposób i w jakim celu ich używamy?</strong></li>
                    <li><strong>VII. Jak zmienia się ustawienia plików Cookie w najbardziej popularnych wyszukiwarkach.</strong></li>
                    <li><strong>VIII. Prawa Użytkownika.</strong></li>
                    <li><strong>IX. Informacje dodatkowe.</strong></li>
                    <li><strong>X. Postanowienia końcowe.</strong></li>
                </ul>
                <h2>W skrócie</h2>
                <p>
                W związku z tym, że zachowanie poufności przekazywanych przez Państwa informacji jest dla nas równie ważne, w niniejszej Polityce Prywatności określamy cel i sposób przetwarzania danych oraz określamy nasze zobowiązania w zakresie powierzonych nam danych.
                </p>
                <p>
                Gdy użytkownik, jako osoba fizyczna, kontaktuje się z nami lub korzysta z naszych usług, niezależnie od tego, czy działa w swoim imieniu, czy w imieniu innego podmiotu (np. naszego klienta, dostawcy itp.), lub gdy otrzymaliśmy jego dane osobowe z innych źródeł (np. publicznie dostępnych branżowych stron internetowych lub gdy dane użytkownika zostały nam ujawnione jako dane kontaktowe w celu realizacji umów), rozpoczynamy przetwarzanie jego danych osobowych. Wszystkie informacje o użytkowniku przetwarzamy w sposób odpowiedzialny i zgodny z przepisami prawa.
                </p>
                <p>
                Poniższe informacje mają na celu wyjaśnienie, kim jesteśmy, w jaki sposób pozyskujemy informacje i co z nimi robimy w naszych relacjach biznesowych, handlowych i marketingowych oraz w związku z kontaktowaniem się z nami. Jeśli cokolwiek jest dla Ciebie niezrozumiałe lub budzi Twoje wątpliwości, skontaktuj się z nami.
                </p>
                <h2>I. Słowniczek - podstawowe pojęcia</h2>
                <ol>
                    <li><strong>„Administrator”</strong> oznacza osobę fizyczną lub prawną, organ publiczny, jednostkę lub inny podmiot, który samodzielnie lub wspólnie z innymi ustala cele i sposoby przetwarzania danych osobowych; jeżeli cele i sposoby takiego przetwarzania są określone w prawie Unii lub w prawie państwa członkowskiego, to również w prawie Unii lub w prawie państwa członkowskiego może zostać wyznaczony administrator lub mogą zostać określone konkretne kryteria jego wyznaczania.</li>
                    <li><strong>„Dane osobowe”</strong> oznaczają wszelkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej.</li>
                    <li><strong>„Przetwarzanie”</strong> oznacza operację lub zestaw operacji wykonywanych na danych osobowych lub zestawach danych osobowych w sposób zautomatyzowany lub niezautomatyzowany, taką jak zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie.</li>
                    <li><strong>„Odbiorca danych”</strong> oznacza osobę fizyczną lub prawną, organ publiczny, jednostkę lub inny podmiot, któremu ujawnia się dane osobowe, niezależnie od tego, czy jest stroną trzecią</li>
                    <li><strong>„Zgoda osoby”,</strong> której dane dotyczą oznacza dobrowolne, konkretne, świadome i jednoznaczne okazanie woli, którym osoba, której dane dotyczą, w formie oświadczenia lub wyraźnego działania potwierdzającego, przyzwala na przetwarzanie dotyczących jej danych osobowych.</li>
                    <li><strong>„Pliki Cookies”</strong> - oznacza dane informatyczne, w szczególności niewielkie pliki tekstowe, zapisywane i przechowywane na urządzeniach za pośrednictwem, których Użytkownik korzysta ze stron internetowych Serwisu.</li>
                    <li><strong>„Serwis”</strong> - oznacza stronę internetową, pod którą Administrator prowadzi serwis internetowy, działającą w domenie: <a href='https://www.polskioutsourcing.pl/' target='_blank'>https://www.polskioutsourcing.pl/</a></li>
                    <li><strong>„Stowarzyszenie” </strong>dobrowolne, samorządne, trwałe zrzeszenie o celach niezarobkowych, posiadające osobowość prawną. Stowarzyszenie samodzielnie określa swoje cele, programy działania i struktury organizacyjne oraz uchwala akty wewnętrzne dotyczące jego działalności.</li>
                    <li><strong>„Urządzenie”</strong> - oznacza elektroniczne urządzenie za pośrednictwem, którego Użytkownik uzyskuje dostęp do stron internetowych Serwisu.</li>
                    <li><strong>„Użytkownik”</strong> - oznacza podmiot, na rzecz którego zgodnie z Regulaminem i przepisami prawa mogą być świadczone usługi drogą elektroniczną lub z którym może być zawarta Umowa o świadczenie usług drogą elektroniczną.</li>
                    <li><strong>„RODO”</strong> - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).</li>
                </ol>
                <h2>II. Administrator</h2>
                <p>Administratorem Państwa danych osobowych jest:</p>
                 <ul>
                    <li><strong>Polskie Stowarzyszenie Outsourcingu</strong> z siedzibą ul. …………………, 00-000 Wrocław, NIP ………….., REGON ……….. jako właściciel i zarządzający stroną <a href='https://www.polskioutsourcing.pl/' target='_blank'>https://www.polskioutsourcing.pl/</a><br></br>W sprawach związanych z przetwarzaniem danych osobowych można się skontaktować przy pomocy poczty elektronicznej wysyłając korespondencję na ogólny adres e-mail: biuro@. <a href="mailto:beata.pilichowska@polskioutsourcing.pl">beata.pilichowska@polskioutsourcing.pl</a> oraz tradycyjną poczta na adres korespondencyjny ul………………….., 00-000 Wrocław z dopiskiem „RODO”.</li>
                 </ul>
                 <h2>III. Jakie dane osobowe gromadzimy oraz jaki jest cel i podstawa prawna naszych działań?</h2>
                 <p>
                 Zakres przetwarzanych przez nas danych zależy od tego w jakim celu i jakie informacje nam przekazujesz oraz od wybranej przez Ciebie formy kontaktu i tego, jakie informacje są wymagane w związku z naszą relacją.
                 </p>
                 <p>
                 Państwa dane osobowe będą przetwarzane w celu:
                 </p>
                 <ol type="a">
                    <li>marketingu produktów i usług - podstawę przetwarzania stanowi <strong>art. 6 ust.1 lit. a „zgoda” RODO</strong> oraz <strong>art. 172 ust. 1 Prawa telekomunikacyjnego,</strong></li>
                    <li>zapamiętania Państwa preferencji i ustawień takich jak: login, hasło, adres IP, data i godzina logowania - wówczas podstawą przetwarzania będzie  <strong>art. 6 ust.1 lit. a „zgoda” RODO</strong> oraz <strong>art. 173 ust. 1 Prawa telekomunikacyjnego,</strong></li>
                    <li>wysyłanie ofert związanych z działaniem stowarzyszenia, newsletteru zgodnie z <strong> art. 6 ust. 1 lit. a RODO,</strong></li>
                    <li>rozpatrywania skarg, reklamacji i wniosków i w ramach tych działań udzielania odpowiedzi na podstawie <strong>art. 6 ust. 1 lit. c RODO,</strong></li>
                    <li>realizacji umów, ewentualnego ustalenia, dochodzenia, egzekucji roszczeń lub obrony przed roszczeniami, które są realizacją prawnie uzasadnionego interesu Administratora (jak np. windykacja należności, prowadzenie postępowań sądowych i egzekucyjnych) na podstawie <strong> art. 6 ust. 1 lit. f RODO,</strong></li>
                    <li>zapobiegania nadużyciom i oszustwom – w celu zapewnienia bezpieczeństwa w zakresie świadczonych Usług zgodnie z <strong>art. 6 ust. 1 lit. f RODO.</strong></li>
                 </ol>
                 <p>Podstawa prawna powyższych działań zależy od kontekstu komunikacji. Jeśli są to ogólne zapytania lub rozmowy, podstawą będzie nasz "prawnie uzasadniony interes" jako administratora (zgodnie z celami wskazanymi powyżej; <strong>art. 6 ust. 1 lit. f RODO).</strong></p>
                 <p>
                 Odwiedzając naszą witrynę internetową, użytkownik może wyrazić zgodę na korzystanie przez nas z technologii plików cookie, która umożliwia prawidłowe funkcjonowanie naszej witryny internetowej, a także analizę informacji o sposobie korzystania z tej witryny.
                 </p>
                 <p>
                 Przetwarzamy te dane w celu poprawy jakości naszej strony internetowej, dopasowania treści do zainteresowań odwiedzających oraz ciągłego ulepszania jej działania. Niektóre pliki cookie umożliwiają również marketing naszych produktów i usług na naszej stronie internetowej.
                 </p>
                 <p>
                 Podstawą prawną korzystania z plików cookie i podobnych technologii jest zgoda użytkownika, z wyjątkiem sytuacji, gdy ich użycie jest niezbędne do działania naszej strony internetowej, gdzie opieramy takie użycie na przepisie prawa<strong> (art. 173 ust. 3 pkt 2 Prawa telekomunikacyjnego)</strong> i odpowiednio naszych uzasadnionych interesach <strong> (art. 6 ust. 1 lit. f RODO).</strong>
                 </p>
                 <p>
                 Jeśli nie przekazałeś nam swoich danych we własnym imieniu, tj. działasz w imieniu innego podmiotu, przetwarzamy Twoje dane w celu nawiązania kontaktu, w kontekście którego działasz w imieniu osoby trzeciej, oraz zawarcia lub wykonania umowy z tą osobą trzecią lub realizacji wspólnego przedsięwzięcia. Podstawą przetwarzania Twoich danych osobowych w tym celu będzie nasz prawnie uzasadniony interes <strong> (art. 6 ust. 1 lit. f RODO) </strong> - budowanie i utrzymywanie relacji z podmiotem trzecim, w imieniu którego działasz, w tym zawieranie i wykonywanie stosownych umów z tym podmiotem trzecim, a także zamiar budowania pozytywnego wizerunku Polskiego Stowarzyszenia Outsourcingu.
                 </p>
                 <p>
                 Niezależnie od powyższego, Twoje dane osobowe, tj. przede wszystkim imię i nazwisko, adres e-mail i numer telefonu, mogą być przez nas wykorzystywane do wysyłania Ci okazjonalnej korespondencji (np. życzeń świątecznych) lub do kontaktowania się z Tobą w sprawie promocji produktów lub usług. Polskie Stowarzyszenie Outsourcingu . Podstawą przetwarzania Twoich danych osobowych w tym celu będzie nasz prawnie uzasadniony interes <strong> (art. 6 ust. 1 lit. f RODO)</strong> - chęć utrzymania naszych relacji, budowania pozytywnego wizerunku Polskiego Stowarzyszenia Outsourcingu oraz marketingu produktów lub usług Polskiego Stowarzyszenia Outsourcingu.
                 </p>
                 <p>
                 Ponadto, jeśli przetwarzamy Dane Osobowe użytkownika w celu obrony przed potencjalnymi roszczeniami, podstawą przetwarzania Danych Osobowych użytkownika w tym celu będzie nasz prawnie uzasadniony interes <strong> (art. 6 ust. 1 lit. f RODO).</strong>
                 </p>
                 <p>
                 W każdym przypadku wysyłamy informacje handlowe na adresy elektroniczne (e-mail/telefon) wyłącznie za uprzednią zgodą użytkownika (podstawa prawna).
                 </p>
                 <p>
                 Podanie przez Ciebie danych osobowych jest dobrowolne, jednak niezbędne do udzielenia odpowiedzi na Twoje zapytanie lub w celu prowadzenia korespondencji.
                 </p>
                 <p>
                 W przypadku przetwarzania danych na podstawie zgody przypominamy, że uprzednio wyrażoną zgodę można wycofać w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Jeżeli natomiast Twoje dane są przetwarzane na podstawie prawnie uzasadnionego interesu Administratora, przysługuje Ci prawo wniesienia sprzeciwu wobec takiego przetwarzania.
                 </p>
                 <p>
                 Podane przez użytkownika dane osobowe nie podlegają zautomatyzowanemu podejmowaniu decyzji ani profilowaniu.
                 </p>
                 <p>
                 Podane przez użytkownika dane osobowe będą przetwarzane wyłącznie na terenie Europejskiego Obszaru Gospodarczego lub w państwie trzecim zapewniającym odpowiedni poziom ochrony, zgodnie z aktualnie obowiązującą decyzją Komisji Europejskiej.
                 </p>
                 <h2>
                 IV. Jak długo przetwarzamy Twoje dane?
                 </h2>
                 <p>
                 Co do zasady, dane przetwarzane na podstawie naszego uzasadnionego interesu będą przetwarzane do momentu wyrażenia sprzeciwu lub osiągnięcia celu, dla którego zostały przetworzone.
                 </p>
                 <p>
                 Jeśli nie jesteś naszym kontrahentem lub partnerem biznesowym, przechowujemy dane zebrane wyłącznie w związku z bieżącym kontaktem, przez okres kilku tygodni (niektóre pliki cookie), maksymalnie przez <strong>5 lat</strong>  (bardziej szczegółowe zapytania i rozmowy, które mogą być istotne dla naszego przyszłego kontaktu), w zależności od kategorii poszczególnych informacji.
                 </p>
                 <p>
                 Jeśli jesteś naszym kontrahentem lub partnerem biznesowym, członkiem stowarzyszenia, przechowujemy dane zebrane wyłącznie w związku z realizacją umowy przez okres <strong>5 lat</strong> od końca roku rozliczeniowego w którym zakończyła się współpraca.
                 </p>
                 <p>
                 Dane przetwarzane wyłącznie na podstawie zgody użytkownika są przetwarzane do czasu ewentualnego cofnięcia zgody przez użytkownika lub do czasu osiągnięcia celu, dla którego zgoda została udzielona.
                 </p>
                 <h2>
                 V. Kto faktycznie ma dostęp do danych osobowych użytkownika?
                 </h2>
                 <p>
                 Dostęp do danych osobowych użytkownika mają wyłącznie:
                 </p>
                 <ul>
                    <li>
                    należycie upoważnionym pracownikom, którzy są zobowiązani do zachowania poufności i niewykorzystywania ich do celów innych niż te, dla których uzyskali dane;
                    </li>
                    <li>
                    podmiotom wspierającym nas w świadczeniu naszych usług, na podstawie stosownych umów powierzenia danych osobowych do przetwarzania, takim jak platformy e-mail marketingowe, dostawcy narzędzi do kontaktu (komunikatory internetowe), dostawcy usług prawnych i doradczych, dostawcy usług hostingowych, kurierzy.
                    </li>
                 </ul>
                 <p>
                 Wszystkie te podmioty mają dostęp wyłącznie do informacji, które są im niezbędne do prowadzenia określonych działań i realizacji celu określonego przez Polskie Stowarzyszenie Outsourcingu.
                 </p>
                 <h2>VI. Czym są pliki cookie i inne podobne technologie? W jaki sposób i w jakim celu ich używamy?</h2>
                 <p>
                 Cookies to niewielkie informacje tekstowe, wysyłane przez serwer i zapisywane po stronie użytkownika (w pamięci komputera, telefonu, tabletu lub innego urządzenia, z którego korzysta łącząc się z naszymi usługami).
Przechowuje informacje, których możemy potrzebować, aby dostosować się do korzystania z naszej strony internetowej i gromadzić dane statystyczne.
                 </p>
                 <p>
                 Gdy użytkownik odwiedza naszą witrynę internetową, mamy możliwość gromadzenia danych o nazwie domeny dostawcy usług internetowych, typie przeglądarki, typie systemu operacyjnego, adresie IP, odwiedzanych stronach internetowych, pobranych elementach, a także danych operacyjnych lub informacji o lokalizacji używanego urządzenia.
                 </p>
                 <p>
                 Zapewniamy, że wszystkie otrzymane w ten sposób informacje są przez nas wykorzystywane wyłącznie do celów określonych w niniejszej polityce i w żadnym wypadku nie są szkodliwe dla użytkownika ani dla używanego przez niego urządzenia, ponieważ nie wprowadzają żadnych zmian w konfiguracji.
                 </p>
                 <p>
                 Pliki cookie używane na stronie internetowej:
                 </p>
                 <ol type='a'>
                    <li><strong>Techniczne</strong> - obejmuje pliki cookie: niezbędne do prawidłowego działania strony internetowej w celu umożliwienia jej funkcjonalności, ale ich działanie nie ma nic wspólnego ze śledzeniem użytkownika.</li>
                    <li><strong>Analityczne</strong> - wykorzystywane do analizy zachowań użytkowników w ramach serwisu, w celach statystycznych i analitycznych (usprawnienie działania serwisu), nie zawierają jednak informacji pozwalających na identyfikację danych konkretnego użytkownika</li>
                    <li><strong>Marketing</strong> - wykorzystywany do analizy zachowań użytkowników i dostarczania informacji identyfikujących dane konkretnego użytkownika, w tym do celów marketingowych w witrynach internetowych osób trzecich.</li>
                 </ol>
                 <p>
                 Oczywiście użytkownik może zmienić sposób korzystania z plików cookie, w tym całkowicie je zablokować lub usunąć za pomocą przeglądarki internetowej lub konfiguracji usługi. Musisz jednak pamiętać, że takie operacje mogą uniemożliwić lub znacząco utrudnić prawidłowe funkcjonowanie naszej strony internetowej, na przykład poprzez znaczne jej spowolnienie, dlatego zalecamy, abyś nie wyłączał ich w swojej przeglądarce.
                 </p>
                 <h2>VII. Jak zmienia się ustawienia plików Cookie w najbardziej popularnych wyszukiwarkach.</h2>
                 <p>
                 Mozilla Firefox<br></br>
                    Instrukcja na stronie: <a href="https://support.mozilla.org/en-US/kb/storage?as=u&utm_source=inproduct&redirectslug=permission-store-data&redirectlocale=en-US" target="blank" >https://support.mozilla.org/en-US/kb/storage?as=u&utm_source=inproduct&redirectslug=permission-store-data&redirectlocale=en-US</a>
                 </p>
                 <p>
                 Opera<br></br>
                 Instrukcja na stronie: <a href='https://help.opera.com/pl/latest/security-and-privacy/'>https://help.opera.com/pl/latest/security-and-privacy/</a>
                 </p>
                 <p>
                 Safari<br></br>
                 Instrukcja na stronie: <a href='https://support.apple.com/pl-pl/guide/safari/sfri11471/mac'>https://support.apple.com/pl-pl/guide/safari/sfri11471/mac</a>
                 </p>
                 <p>
                 Google Chrom<br></br>
                 Instrukcja na stronie: <a href='https://support.google.com/chrome/answer/95647?hl=pl&co=GENIE.Platform%3DDesktop'>https://support.google.com/chrome/answer/95647?hl=pl&co=GENIE.Platform%3DDesktop</a>
                 </p>
                 <h2>
                    VIII. Prawa Użytkownika.
                 </h2>
                 <p>
                 Użytkownik, który przekazał Współadministratorom swoje dane osobowe, ma prawo do:
                 </p>
                 <ol type='a'>
                    <li>dostępu do swoich danych oraz otrzymania ich kopii;</li>
                    <li>sprostowania (poprawiania) swoich danych;</li>
                    <li>usunięcia danych (tzw. prawo do bycia zapomnianym);</li>
                    <li>ograniczenia przetwarzania danych osobowych;</li>
                    <li>wniesienia sprzeciwu wobec przetwarzania danych, jak również przetwarzania na potrzeby marketingu bezpośredniego;</li>
                    <li>cofnięcia zgody w przypadku, gdy Administrator będzie przetwarzał dane Użytkownika w oparciu o zgodę, w dowolnym momencie i w dowolny sposób, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem;</li>
                    <li>wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy Użytkownik uzna, że przetwarzanie danych osobowych narusza przepisy RODO.</li>
                 </ol>
                 <p>
                 Skargę można wnosić do:
                 </p>
                 <h4>Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</h4>
                 <h2>IX. Informacje dodatkowe.</h2>
                 <h4><a href="https://policies.google.com/technologies/partner-sites" target='_blank'>https://policies.google.com/technologies/partner-sites</a></h4>
                 <h2>X. Postanowienia końcowe.</h2>
                 <p>
                 W zakresie nieobjętym niniejszą polityką prywatności stosuje się odpowiednie przepisy prawa polskiego.
                 </p>
                 <p>
                 Współadministratorzy zastrzegaja sobie prawo wprowadzania zmian w polityce prywatności będących wynikiem zmiany regulacji prawnych.
                 </p>
                 <p>
                 Współadministratorzy zastrzegaja sobie prawo do zmiany w polityce prywatności strony, która może wynikać z postępu technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój Serwisu. O wszelkich zmianach będziemy informować użytkowników w sposób widoczny i zrozumiały.
                 </p>
                 <p>
                 Niniejsza Polityka Prywatności obwiązuje od dnia ............................
                 </p>
            </div>
        </div>
    );
}

export default Privacy;