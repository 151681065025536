import React from 'react';
import './ForShortPost5.scss';
import arrowLeft from '../../../assets/img/arrow-left.svg';
import { HashLink } from 'react-router-hash-link';

function ForShortPost5() {

  return (
    <div className="post5">
      <div className="post-hero">
        <div className="post5-capture">
        21 maja w Bydgoszczy odbędzie się Konferencja Biznes i Nauka.
        </div>
      </div>
      <div className="post5-content-wrapper">
        <div className="post-navigate">
          <HashLink to={'/#porady'} smooth>
            <div className="back-to-main">
              <div className="arrow"><img src={arrowLeft} alt="" /></div>
              <div className="back">
                Back to articles
              </div>
            </div>
          </HashLink>
          <div className="post-date">
            
          </div>
        </div>
        <p>21 maja w Bydgoszczy odbędzie się Konferencja Biznes i Nauka - Kompetencje przyszłości w dobie AI, której głównym partnerem jest Polskie Stowarzyszenie Outsourcingu. To wydarzenie to przede wszystkim spotkania wiedzy naukowej, eksperckiej HR-owej i biznesowej wśród liderów firm i organizacji, naukowców i ekspertów, których głównym obszarem działania jest dział HR.</p>
        </div>
    </div>
  );
}

export default ForShortPost5