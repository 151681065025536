import { useEffect,useRef,useState } from "react";

export default function useOutsideAlerter(initialIsVisible){
    const [isShowLeft,setIsShowLeft] = useState(initialIsVisible);
    const refLeft = useRef(null)

    const handleClickOutsideLeft = (event) => {
        if (refLeft.current && !refLeft.current.contains(event.target)) {
            setIsShowLeft(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click',handleClickOutsideLeft, true)
        return () => {
            document.removeEventListener('click',handleClickOutsideLeft,true)
        }
    });

    const [isShowRight,setIsShowRight] = useState(initialIsVisible);
    const refRight = useRef(null)

    const handleClickOutsideRight = (event) => {
        if (refRight.current && !refRight.current.contains(event.target)) {
            setIsShowLeft(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click',handleClickOutsideRight, true)
        return () => {
            document.removeEventListener('click',handleClickOutsideRight,true)
        }
    });


    return [
        refLeft,
        isShowLeft,
        setIsShowLeft,
        refRight,
        isShowRight,
        setIsShowRight
    ]
}
