import React from 'react';
import './Targets.scss';
import targetImg1 from '../../assets/img/target1.svg';
import targetImg2 from '../../assets/img/target2.svg';
import targetImg3 from '../../assets/img/target3.svg';
import targetImg4 from '../../assets/img/target4.svg';
import targetBottomImg from '../../assets/img/targetBottom.png';

function Targets() {

  const targetCardIData = [
    {
      img: targetImg1,
      text: <span>Działania na rzecz <strong>edukacji i podnoszenia kompetencji pracowników</strong> branży outsourcingowej</span>
    },
    {
      img: targetImg2,
      text: <span><strong>Tworzenie dobrych praktyk</strong> w prawidłowym świadczeniu usług outsourcingowych w Polsce</span>
    },
    {
      img: targetImg3,
      text: <span><strong>Ułatwienie współpracy między firmami</strong> outsourcingowymi oraz instytucjami kontrolnymi</span>
    },
    {
      img: targetImg4,
      text: <span><strong>Tworzenie warunków do rozwoju firm</strong> outsourcingowych i poprawy konkurencyjności polskiej branży outsourcingowej</span>
    },
  ];

  const targetCardIItem = targetCardIData.map((item,index) => (
    <div key={index} className="card">
      <div className="card-content">
        <div className="card-img">
          <img src={item.img} alt="card" />
        </div>
        <div className="card-description">
          {item.text}
        </div>
      </div>
    </div>
  ))

  return (
    <div id='cel' className="targets-main">
      <div className="targets-text">
        <div className="targets-capture">
          NASZ CEL
        </div>
        <div className="targets-description">
          Celem <strong>Polskiego Stowarzyszenia Outsourcingu</strong> jest
          wspieranie rozwoju sektora outsourcingu usług biznesowych
          (BPO), czy outsourcingu procesów biznesowych (BPO) i
          innych dziedzin związanych z outsourcingiem w Polsce.
          PSO angażuje się w działania mające na celu:
        </div>
      </div>
      <div className="targets-card-wrapper">
        {targetCardIItem}
      </div>
      <div className="target-bottom">
        <div className="target-bottom-content">
          <div className="target-bottom-text">
            Stowarzyszenie organizuje różnego rodzaju wydarzenia, szkolenia,
            konferencje i spotkania networkingowe, które pomagają firmom
            outsourcingowym nawiązywać kontakty i wymieniać się doświadczeniami.
            PSO także działa na rzecz tworzenia korzystnych warunków prawnych i
            regulacyjnych dla branży outsourcingowej w Polsce.
          </div>
          <div  className="target-bottom-img">
              <img src={targetBottomImg} alt="target" />
          </div>
        </div>
      </div>
      <div id='porady'></div>
    </div>
  );
}

export default Targets;