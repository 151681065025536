import React from 'react';
import './Footer.scss';
import logo from '../../assets/img/Logo.svg';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import ScrollToTop from '../scrollToTop/scrollToTop';
import statut from'../../assets/statut/statut-stowarzyszenia-PSO.pdf'
import Button from '../button/Button';

function Footer() {
  const handleDownload = async () => {
    try {
      const response = await fetch(statut);
      const fileData = await response.arrayBuffer();
      const blob = new Blob([fileData], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Statut.pdf';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error('Ошибка при скачивании файла:', error);
    }
  };
  return (
    <div id='footer' className="footer-main">
      <div className="footer-content-wrapper">
        <div className="footer-logo">
          <Link to='/'><img src={logo} alt="footerLogo" /></Link>
        </div>
        <div className="footer-links">
          <div className="footer-links-item">
              <ul>
                <li className='subLinks'><HashLink to={'/#Onas'} smooth>O nas</HashLink></li>
                <li className='subLinks'><HashLink to={'/#cel'} smooth>Nasz cel</HashLink></li>
                <li className='subLinks'><HashLink smooth>news</HashLink></li>
              </ul>
              <div className='subLinks' ><HashLink to={'/#WiedzaIporady'} smooth>Wiedza i porady</HashLink></div>
              <div className='subLinks' ><HashLink to={'/#WydarzeniaIwebinary'} smooth>Wydarzenia i webinary</HashLink></div>
              <div className='subLinks' ><HashLink to={'/#outsourcingwPolsce'} smooth>#polskiestowarzyszenieoutsourcingu</HashLink></div>
              <div className='subLinks' ><HashLink to={'/#outsourcingwPolsce'} smooth>#polskioutsourcing</HashLink></div>
              <div className='subLinks' ><HashLink to={'/#outsourcingwPolsce'} smooth>#outsourcingwPolsce</HashLink></div>
          </div>
          <div className="footer-links-item">
            <ul>
              <li className='subLinks'><HashLink to={'/#partnerzy'} smooth>Nasi partnerzy</HashLink></li>
              <li className='subLinks'><HashLink  smooth>Członkostwo</HashLink></li>
              <div className='subLinks' ><HashLink to={'/#Korzyści'} smooth>Korzyści z członkostwa</HashLink></div>
            <div className='subLinks' ><HashLink to={'/#сzłonkowie'} smooth>Członkowie</HashLink></div>
            <div className='subLinks' ><HashLink to={'/#form'} smooth>Deklaracja członkostwa</HashLink></div>
            <li><HashLink to={'/#kontakt'} smooth>Kontakt</HashLink></li>
            <li><Link to="/privacy">Polityka prywatności</Link></li>
            </ul>
          </div>
          <div className="footer-links-item">
              <div className="adress">
              ul. Gwiaździsta 66 lokal 2B.06
              </div>
              <div className="adress">
              53-413, Wrocław
              </div>
            
            <div className="footer-email">
            <a href={`mailto:kontakt@polskioutsourcing.pl`}>kontakt@polskioutsourcing.pl</a>
            </div>
            <div className="adress">
            KRS 0001086709
              </div>
              <div className="adress">
              NIP: 8992984758
              </div>
          </div>
        </div>
       
      </div>
      <ScrollToTop/>
    </div>
  );
}

export default Footer;