import React from 'react';
import './About.scss';
import aboutImg from '../../assets/img/aboutImg.png';

function About() {
  return (
    <div id='Onas' className="about-main">
      <div className="about-content">
        <div className="about-img">
          <img src={aboutImg} alt="aboutPicture" />
        </div>
        <div className="about-text">
          <div className="about-capture">
            O NAS
          </div>
          <div className="about-description">
          Polskie Stowarzyszenie Outsourcingu powstało z inicjatywy osób, reprezentujących środowiska biznesu, akademickie oraz eksperckie. 
          PSO jest organizacją, która działa na rzecz rozwoju i promocji sektora outsourcingu w Polsce. Nasze stowarzyszenie reprezentuje 
          interesy firm outsourcingowych, dostawców usług, inwestorów, czy innych podmiotów związanych z branżą outsourcingu.
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;