import React from 'react';
import './News.scss';
import img1 from '../../assets/img/news-card-img1.png';
import img2 from '../../assets/img/news-card-img2.png';
import img3 from '../../assets/img/news-card-img3.png';
import Slider from 'react-slick';

function News() {

  const newsCardData = [
    {
      img: img1,
      capture: 'Publikować treści związane z outsourcingiem w Polsce',
      description: 'Jeśli jesteś przedstawicielem firmy outsourcingowej lub ekspertem w tej dziedzinie, możesz używać hashtagu #outsourcingwpolsce w swoich postach na platformach społecznościowych, aby dotrzeć do szerszej grupy odbiorców zainteresowanych tym tematem.'
    },
    {
      img: img2,
      capture: 'Śledzić hashtag',
      description: 'Możesz śledzić ten hashtag na platformach społecznościowych, takich jak Twitter, Instagram czy LinkedIn, aby być na bieżąco z informacjami i treściami związanymi z outsourcingiem w Polsce. To doskonały sposób na pozyskanie najnowszych wiadomości i opinii na ten temat.'
    },
    {
      img: img3,
      capture: 'Szukać inspiracji i informacji',
      description: 'Nasze hashtagi mogą być również przydatne, jeśli szukasz inspiracji lub informacji na temat korzyści i wyzwań związanych z outsourcingiem usług biznesowych w Polsce. Możesz znaleźć artykuły, raporty, studia przypadków i opinie innych profesjonalistów.'
    },
  ];

  let itemLength = newsCardData.length;
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: itemLength === 1 ? 1 : (itemLength === 2 ? 1 : 3),
    slidesToScroll: itemLength === 1 ? 1 : (itemLength === 2 ? 1 : 3),
    rows: (itemLength % 6 === 0) ? 2 : 1,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          rows: 1
        }
      },
      {
        breakpoint: 1255,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
     
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
 

  const newsCardItem = newsCardData.map((item) => (
    <div key={item.capture}>
    <div  className="news-item-card">
      <div className="news-item-content">
        <div className="news-item-img">
          <img src={item.img} alt="news-card-img" />
        </div>
        <div className="news-item-capture">
          {item.capture}
        </div>
        <div className="news-item-description">
          {item.description}
        </div>
      </div>
    </div>
    </div>
  ));

  return (
    <div id='outsourcingwPolsce' className="news-main">
      <div className="news-content-wrapper">
        <div className="news-content">
          <div className="news-capture">
            news
          </div>
          <div className="hews-hashtag">
            #POLSKIESTOWARZYSZENIEOUTSOURCINGU
          </div>
          <div className="hews-hashtag">
            #polskioutsourcing
          </div>
          <div className="hews-hashtag">
            #OutsourcingwPolsce
          </div>
          <div className="news-description">
            Powyższe hashtagi są związane z tematem outsourcingu usług biznesowych w Polsce. W taki sposób możecie Państwo identyfikować i śledzić
            w mediach społecznościowych treści związane z tematem outsourcingu, również organizowane przez nas wydarzenia.
          </div>
          <div className="news-card-capture">
            Jeśli jesteś zainteresowany korzystaniem z tego hashtagu lub
            śledzeniem treści z nim związanych, możesz:
          </div>
          <div className="news-card-wrapper">
            {newsCardItem}
          </div>
          <div className="news-card-wrapper-slider">
          <Slider {...settings}>
            {newsCardItem}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;