import React from 'react';
import './Button.scss';

function Button(props) {
  return (
    <button className="button-main" onClick={props.action}>
      {props.text}
    </button>
  );
}

export default Button;