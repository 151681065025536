import React from 'react';
import './Form.scss';
import Button from '../button/Button';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import statut from '../../assets/statut/statut-stowarzyszenia-PSO.pdf';
import axios from "axios";

const handleDownload = async () => {
  try {
    const response = await fetch(statut);
    const fileData = await response.arrayBuffer();
    const blob = new Blob([fileData], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Statut.pdf';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Ошибка при скачивании файла:', error);
  }
};

function Form(props) {

  const [formValid, setFormvalid] = useState(false);
  const [consent, setConsent] = useState(false);

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');

  const [telError, setTelError] = useState('');
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [mailError, setMailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [chkboxError, setChkboxError] = useState('');
  
  const setNameHandler = (event) => {
    setName((prev) => {
      prev = event.target.value;
      if (prev.length <= 1) {
        setNameError('red')
      } else setNameError('')
      return prev;
    });
  };


  const setLastNameHandler = (event) => {
    setLastName((prev) => {
      prev = event.target.value;
      if (prev.length <= 1) {
        setLastNameError('red')
      } else setLastNameError('')
      return prev;
    });
  };

 
  const setPhoneHandler = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9+]/g, '');
    setPhoneValue(inputValue);
    if (inputValue.length <= 5) {
      setTelError('red');
    } else {
      setTelError('');
    }
  };

  
  const setMailHandler = (event) => {
    setMail((prev) => {
      prev = event.target.value;
      const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (!re.test(String(prev).toLocaleLowerCase()) && prev.length !== 0) {
        setMailError('red')
      } else
        setMailError('')
      return prev;
    });
  };

 
  const setMessageHandler = (event) => {
    setMessage((prev) => {
      prev = event.target.value;
      if (prev.length <= 1) {
        setMessageError('red')
      } else setMessageError('')
      return prev;
    });
  };

  const onToggleCheck = () => {
    setConsent(!consent);
}

  useEffect(() => {
    if (nameError == 'red' || mailError == 'red' || telError == 'red' || messageError == 'red' ) {
      setFormvalid(false)
    } else {
      setFormvalid(true)
    }
  }, [nameError, mailError, telError, messageError]);

  useEffect(()=> {
    if(consent){
      setChkboxError('')
    }
  },[consent])


  const onClickSendButtonHandler = () => {
    if (formValid) {
      if(!consent){
        setChkboxError('red')
      }else {
        try {
            axios.get(`https://kbf83va699.execute-api.eu-central-1.amazonaws.com/default/send_smpt?email=${mail}&name=${name}&lastName=${lastName}&body=${message}&phone=${phoneValue}`, {
            }).then((response) => {
              if (response.status === 200) {
                setName('');
                setLastName('');
                setPhoneValue('');
                setMail('');
                setMessage('');
                setConsent(false)
                props.handlerSelectModal(true);
            } else {
                console.log(`Server responded with status ${response.status}`);
            }
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    console.log(error);
                } else {
                    console.error(error);
                }
            });
        } catch (error) {
            console.error(error);
        }
      }
    }
};

  return (
    <div id='form' className="Form-main" >
      <form className="form-content" method='post' onSubmit={(e) => e.preventDefault()}>
        <div className="form-top-capture">
          CZŁONKOWIE
        </div>
        <div className="form-capture">
          Deklaracja członkostwa
        </div>
        <div className="form">
          <div className="inputs-line">
            <div className='input-block'>
              <div className='signature'><label htmlFor="Imię">Imię </label></div>
              <div className={`inputs-wrapper ${nameError}`}>
                <input

                  type="text"
                  id="Imię"
                  placeholder='Imię'
                  onChange={setNameHandler}
                  value={name}
                />
              </div>
            </div>
            <div className='input-block'>
              <div className='signature'><label htmlFor="Nazwisko">Nazwisko</label></div>
              <div className={`inputs-wrapper ${lastNameError}`}>
                <input
                  type="text"
                  id="Nazwisko"
                  placeholder='Nazwisko'
                  onChange={setLastNameHandler}
                  value={lastName}
                />
              </div>
            </div>
          </div>
          <div className="inputs-line">
            <div className='input-block'>
              <div className='signature'><label htmlFor="tel">Numer telefonu </label></div>
              <div className={`inputs-wrapper ${telError}`}>
                <input
                  type="tel"
                  id="tel"
                  placeholder='Numer telefonu'
                  pattern="[0-9+]*"
                  onChange={setPhoneHandler}
                  value={phoneValue}
                />
              </div>
            </div>
            <div className='input-block'>
              <div className='signature'><label htmlFor="Email">Email</label></div>
              <div className={`inputs-wrapper ${mailError}`}>
                <input
                  type="email"
                  id="email"
                  placeholder='Email'
                  onChange={setMailHandler}
                  value={mail}
                />
              </div>
            </div>
          </div>
          <div className="text-area-block">
            <div className='signature'><label htmlFor="textarea">Wiadomość</label></div>
          
            <textarea
              className={`${messageError}`}
              name="Wiadomość"
              id="textarea"
              placeholder='Wiadomość'
              onChange={setMessageHandler}
              value={message}
            >
            </textarea>
      
          </div>
          <div className='checkboxes-wrapper'>
            <div className='checkbox-item-wrapper'>
              <input type="checkbox" checked={consent} onChange={onToggleCheck} id='check' />
              <div className={`checkbox-description-with-link ${chkboxError}`}>
                Wysyłając "deklaracje członkowską" akceptują Państwo rozpoczęcie procesu przetwarzania danych osobowych zawartych w formularzu rejestracyjnym powyżej.
                Dane osobowe beda wykorzystywane zgodnie ze <span className='statut' onClick={handleDownload}>statutem stowarzyszenia</span> w celu komunikacji związanej z członkostwem w Polskim Stowarzyszeniu Outsourcingu z siedziba we Wrocławiu, ul. Gwiazdzista, nr 66, lok. 2B.06, numer KRS: 0001086709, numer
                NIP: 8992984758. Więcej o ochronie danych osobowych w     <Link to="/privacy">Polityce Prywatności.</Link>
              </div>
            </div>

          </div>
          <div className="button-wrapper" onClick={()=> {onClickSendButtonHandler(); }}>
            <Button text={"Wyślij zgłoszenie"}  />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Form;