import React from 'react';
import '../app/App.scss';
import Hero from '../../components/hero/Hero';
import About from '../../components/about/About';
import Targets from  '../../components/targets/Targets';
import Blog from '../../components/blog/Blog';
import OutsourceNews from '../../components/news/News';
import Advantages from  '../../components/advantages/Advantages';
import Partners from '../../components/partners/Partners';
import Form from '../../components/form/Form';
import VebinarsBlog from '../../components/blog/vebinarsBlog/VebinarsBlog';
import ContactUs from '../../components/contactUs/ContactUs';

function Home( props) {
  return (
    <>

      <Hero/>
      <Blog/>
      <About/>
      <Targets/>
      <VebinarsBlog/>
      <OutsourceNews/>
      <Advantages/>
      <Partners/>
      <Form handlerSelectModal={props.handlerSelectModal}/>
     <ContactUs/>
    </>
  );
}

export default Home;