import React from 'react';
import './Hero.scss';

function Hero() {
  return (
    <div className="hero-main-wrapper">
      <div className="hero-main">
        <div className="hero-content">
          <div className="hero-capture">
            Związek liderów sektora usług outsourcingowych
          </div>
          <div className="hero-description">
            Z nami zwiększysz swoją konkurencyjność
          </div>
        </div>
      </div>
      </div>
  );
}

export default Hero;