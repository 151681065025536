import React from 'react';
import './HeaderMob.scss';
import logo from '../../../assets/img/Logo.svg';
import burger from "../../../assets/img/burger.svg";
import close from '../../../assets/img/close.svg';
import OutsideHook from '../../outsideHook/OutsideHook';
import useOutsideAlerter from '../../additional-components/useOutsideAlerter';
import arrowDown from '../../../assets/img/arrowDown.svg';
import arrowUp from '../../../assets/img/arrowUp.svg';
import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link,useNavigate } from 'react-router-dom';

function HeaderMob() {
    const [refLeft, isShowLeft, setIsShowLeft] = useOutsideAlerter(false);
    const [refRight, isShowRight, setIsShowRight] = useOutsideAlerter(false);

    const [ref, isShow, setIsShow] = OutsideHook(false);
    const dropMenu = () => {
        setIsShow(!isShow);
    }
    const dropMenuClose = () => {
        setIsShow(false);
    }
    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.style.overflow = isShow ? "hidden" : "auto";
        }
    }, [isShow]);
    const navigate = useNavigate();

    return (
        <>
            <div className={`header-mob-main `}>
                <div className="header-mob-content">
                    <div className="logo" onClick={() => {
                        navigate('/');
                        dropMenuClose();
                    }}>
                        <img src={logo} alt="mobLogo" />
                    </div>
                    <div className="burger" onClick={() => {
                        dropMenu();
                    }}>
                        <img src={isShow ? close : burger} alt="burger" />
                    </div>
                </div>
                {isShow &&
                    <div className="drop-down-menu" >
                        <div className="drop-item" onClick={() => {
                            dropMenu();
                        }}>
                            <HashLink to={'/#Onas'} smooth>O nas</HashLink>
                        </div>
                        <div className="drop-item" onClick={() => {
                            dropMenu();
                        }}>
                            <HashLink to={'/#cel'} smooth>Nasz cel</HashLink>
                        </div>
                        <div className="drop-item-group" style={{ padding: isShowLeft ? '0px 0px 8px 0px' : '0px 0px 20px 0px' }}>
                            <div onClick={() => setIsShowLeft(!isShowLeft)}>News</div>
                            <div className='mob-arrow-down'><img src={isShowLeft ? arrowUp : arrowDown} alt="arrowDown" /></div>
                        </div>
                        {isShowLeft && (
                            <div className="drop-item">
                                <div className="header-mob-drop-down-item-link" onClick={() => {
                                    dropMenu();
                                }}>
                                    <HashLink to={'/#WiedzaIporady'} smooth>Wiedza i porady</HashLink>
                                </div>
                                <div className="header-mob-drop-down-item-link" onClick={() => {
                                    dropMenu();
                                }}>
                                    <HashLink to={'/#WydarzeniaIwebinary'} smooth>Wydarzenia i webinary</HashLink>
                                </div>
                                <div className="header-mob-drop-down-item-link" onClick={() => {
                                    dropMenu();
                                }}>
                                    <HashLink to={'/#outsourcingwPolsce'} smooth>#outsourcingwPolsce</HashLink>
                                </div>
                            </div>
                        )}
                        <div className="drop-item" onClick={() => {
                            dropMenu();
                        }}>
                            <HashLink to={'/#partnerzy'} smooth>Nasi partnerzy</HashLink>
                        </div>
                        <div className="drop-item-group" style={{ padding: isShowLeft ? '0px 0px 8px 0px' : '0px 0px 20px 0px' }}>
                            <div onClick={() => setIsShowRight(!isShowRight)}>Członkonstwo</div>
                            <div className='mob-arrow-down'><img src={isShowRight ? arrowUp : arrowDown} alt="arrowDown" /></div>
                        </div>
                        {isShowRight && (
                            <div className="drop-item">
                                <div className="header-mob-drop-down-item-link" onClick={() => {
                                    dropMenu();
                                }}>
                                    <HashLink to={'/#Korzyści'} smooth>Korzyści z członkostwa</HashLink>
                                </div>
                                <div className="header-mob-drop-down-item-link" onClick={() => {
                                    dropMenu();
                                }}>
                                    <HashLink to={'/#сzłonkowie'} smooth>Członkowie</HashLink>
                                </div>
                                <div className="header-mob-drop-down-item-link" onClick={() => {
                                    dropMenu();
                                }}>
                                    <HashLink to={'/#form'} smooth>Formularz rejestracyjne</HashLink>
                                </div>
                                
                            </div>

                        )}
                        <div className="drop-item" onClick={() => {
                            dropMenu();
                        }}>
                            <HashLink to={'/#kontakt'} smooth>Kontakt</HashLink>
                        </div>
                    </div>
                }
            </div>

        </>
    );
}

export default HeaderMob;