import React from 'react';
import './ForShortPost3.scss';
import arrowLeft from '../../../assets/img/arrow-left.svg';
import { HashLink } from 'react-router-hash-link';

function ForShortPost3() {

  return (
    <div className="post3">
      <div className="post-hero">
        <div className="post3-capture">
          Outsourcing i praca tymczasowa, które rozwiązanie jest bardziej opłacalne?
        </div>
      </div>
      <div className="post3-content-wrapper">
        <div className="post-navigate">
          <HashLink to={'/#porady'} smooth>
            <div className="back-to-main">
              <div className="arrow"><img src={arrowLeft} alt="" /></div>
              <div className="back">
                Back to articles
              </div>
            </div>
          </HashLink>
          <div className="post-date">
            
          </div>
        </div>
        <h3>Outsourcing i praca tymczasowa, które rozwiązanie jest bardziej opłacalne?</h3>
        <ul>
          <li>Praca tymczasowa czy outsourcing?</li>
          <li>Praca tymczasowa, inaczej leasing pracowniczy</li>
          <li>Czym jest outsourcing? Jak rozumieć to pojęcie?</li>
          <li>Po co więc ubiera się pracę tymczasową w buty outsourcingu?</li>
        </ul>
        <h2>Praca tymczasowa czy outsourcing? Oto jest pytanie.</h2>
        <p>
          Dyskusja o wyższości jednej koncepcji współpracy z dostawcami usług nad drugą toczy się nieprzerwanie od kilku lat. Zwolennicy i przeciwnicy obu rozwiązań przerzucają się raportami, tabelami, przykładami dobrych i złych, a także niekoniecznie legalnych, praktyk. Chaos komunikacyjny i mnogość rozwiązań na rynku powoduje, że osoby decyzyjne w firmach produkcyjnych i logistycznych muszą często kierować się intuicją, warunkami cenowymi czy... urokiem osobistym handlowca.
        </p>
        <h3>Co może dać Ci rynek?</h3>
        <p>
          Agencje pracy tymczasowej mogą wesprzeć Cię w kilku obszarach: pracy tymczasowej, outsourcingu procesów oraz rekrutacjach stałych. Większość podmiotów obecnych na rynku świadczy jednocześnie wszystkie trzy usługi. Niestety, nie wszystkie w podobnym standardzie i zgodnie z literą prawa. Pominę kwestię rekrutacji stałych, bo to temat na osobny wpis. Dziś skupię się na Pracy Tymczasowej i Outsourcigu - podobieństwach i różnicach, a także wadach i zaletach dla klienta.
        </p>
        <h2>Praca tymczasowa, inaczej leasing pracowniczy</h2>
        <p>
          <b>Praca tymczasowa</b>to sztandarowa kompetencja agencji zatrudnienia. Zgodnie z rejestrem KRAZ, usługę tę może świadczyć na dzień powstania tego artykułu (16.06.2023) aż 5 138 podmiotów.
        </p>
        <div class="vertical-line">
          <p>Praca tymczasowa polega na wykonywaniu na rzecz danego podmiotu - tzw. pracodawcy użytkownika - przez okres nie dłuższy niż wskazany w ustawie o zatrudnianiu pracowników tymczasowych, prac o charakterze sezonowym, okresowym, doraźnym lub których terminowe wykonanie nie byłoby możliwe przez pracowników etatowych zatrudnianych przez ten podmiot albo też należących do obowiązków nieobecnego pracownika zatrudnianego przez pracodawcę użytkownika.</p>
        </div>
        <h3>
          Tyle ustawa, a w praktyce?
        </h3>
        <p>
          W praktyce usługa pracy tymczasowej polega na zleceniu agencji pracy tymczasowej rekrutacji i zatrudnienia pracowników, którzy następnie świadczą pracę w danym zakładzie pracy pod kierownictwem wewnętrznych liderów i kierowników.
        </p>
        <p>
          Zadaniem agencji pracy jest także rozliczenie pracowników, a więc wypłata należnych pensji oraz odprowadzenie składek społecznych, na podstawie ewidencji prowadzonej przez pracodawcę użytkownika.
        </p>
        <p>
          Czasem współpraca z dostawcą usług personalnych wygląda tak, że w pierwszej fazie projektu, a więc przed wprowadzeniem pracowników, kontakt jest systematyczny, niejednokrotnie inicjowany przez samą agencję pracy, a później? Później zdarza się, że konsultant odzywa się tylko wtedy kiedy potrzebuje ewidencji obecności do rozliczenia pracowników. Agencja nie ingeruje, ani nie angażuje się w obsługę klienta i pracownika tymczasowego.
        </p>
        <p>
          Super, jeśli takie są oczekiwania pracodawcy użytkownika. Super, jeśli pracownicy tymczasowi nie skarżą się na jakość obsługi czy brak kontaktu z agencją. Super, jeśli wszystkim trzem stronom kontraktu to odpowiada.
        </p>
        <p>
          Problem pojawia się wtedy, kiedy pracownicy tymczasowi czują się niezaopiekowani, kierownicy zmian mają wrażenie, że ciągle przybywa im obowiązków, a wewnętrzy zespół HR musi mierzyć się z problemami, które co do zasady miała rozwiązać współpraca z agencją.
        </p>
        <h3>Zalety pracy tymczasowej</h3>
        <ul>
          <li>
            <b>minimalizacja działań kadrowo - administracyjnych</b>- agencja prowadzi akta osobowe pracowników, rozlicza czas pracy i opłaca należne składki
          </li>
          <li>
            <b>minimalizacja działań rekrutacyjnych</b>- po stronie agencji jest pozyskanie pracowników na konkretne, określone w zamówieniu stanowiska. Klient może zlecić agencji poszukiwanie pracowników z określonego rynku (np. obcokrajowców), a sam skupić się na rozwoju rekrutacji lokalnej
          </li>
          <li>
            <b>elastyczność</b>- co tu dużo mówić - pracowników tymczasowych znacznie łatwiej i szybciej można zwolnić. Nie ważne jak dużą grupę pracowników tymczasowych uwalniasz na rynek, nie grozi Ci widmo zwolnień grupowych i związanych z nimi zobowiązań finansowych
          </li>
          <li>
            <b>możliwość uzyskania szerokiego wsparcia</b>- o tym, że agencja pracy może wesprzeć wewnętrzne działy firmy pisałam już jakiś czas temu <span className='bigText'>TUTAJ</span>. Faktycznie, dobrze ustawiony proces współpracy na linii Klient - Agencja Pracy może odciążyć wewnętrzne działy operacyjne i HR
          </li>
          <li>
            <b>kontrola i kierowanie</b>- w odróżnieniu od outosurcingu, w pracy tymczasowej pracownicy podlegają pod kierownictwo Pracodawcy Użytkownika. To Twoi liderzy rozliczają ich z efektywności, wydają polecenia i zlecają np. pracę w nadgodzinach
          </li>
        </ul>
        <h3>Wady pracy tymczasowej</h3>
        <ul>
          <li>
            <b>wskaźnik retencji uzależniony od dwóch środowisk</b>- retencja pracowników, czyli ich gotowość do związania się z firmą na dłużej (np. na 18 miesięcy), w przypadku pracy tymczasowej zależy nie tylko od Pracodawcy Użytkownika i warunków jakie zapewnia, ale też w bardzo dużym stopniu o agencji pracy - od kontaktowości, stopnia zaopiekowania, gotowości do udzielania odpowiedzi itd.
          </li>
          <li>
            <b>poczucie braku stabilności wpływa na wyższą rotację</b>- często pracownicy tymczasowi nie czują stabilności zatrudnienia i w trakcie zatrudnienia poszukują pracy "na etat". Dotyczy to głównie pracowników z lokalnego rynku, rzadziej obcokrajowców
          </li>
          <li>
            <b>limit zatrudnienia</b>- maksymalny czas pracy pracownika tymczasowego u jednego Pracodawcy Użytkownika, to 18 miesięcy (o wyjątkach przeczytasz w ustawie, ale nie są to game changery)
          </li>
          <li>
            <b>kontrola i kierowanie</b>- tak, to nie przypadek, że to samo zalicza się zarówno do wad, jak i zalet pracy tymczasowej. Punkt widzenia zawsze zależy od punktu siedzenia....
          </li>
          <li>
            <b>niejednokrotnie duże zaangażowanie zasobów wewnętrznych we wdrożenie i obsługę pracowników tymczasowych oraz kontakt z agencją</b>- niestety, często zdarza się, że współpraca z agencją dobrze układa się tylko na etapie dopinania umowy, potem mamy prawdziwy rollercoaster emocji
          </li>
          <li>
            <b>ograniczona odpowiedzialność agencji pracy tymczasowej</b>
          </li>
        </ul>
        <h2>Czym jest outsourcing? Jak rozumieć to pojęcie?</h2>
        <p>
          Outsourcing powinien ściągnąć 90% problemów związanych z kierowaniem pracownikami tymczasowymi czy zarządzaniem współpracą z agencją pracy.
        </p>
        <p>
          Słowo "outsourcing", to właściwie zlepek trzech angielskich słów: "outside" "resource" i "using", czyli dosłownie: korzystanie z zewnętrznych zasobów.
        </p>
        <p>
          Polega, albo raczej powinien on polegać, na wyodrębnieniu i przekazaniu części zadań, projektów czy procesów do realizacji zewnętrznemu podmiotowi. Jeśli jesteś operatorem logistycznym, to możesz wyoutsourcować np. obsługę zwrotów albo sam proces zbierania zamówień (order-picking). Czyli przekazać go w całości do prowadzenia firmie zewnętrznej.
        </p>
        <p>
          W takiej sytuacji outsourcer, czyli podmiot podejmujący się zlecenia, ma za zadanie zaplanować całość działań, zapewnić personel niezbędny do ich obsługi, kierować pracownikami, weryfikować ich efektywność oraz zgodność przebiegu procesów z ustalonymi normami. Rozliczenie między podmiotami współpracującymi odbywa się za wykonaną usługę, czyli np. per spakowane zamówienie czy zrealizowany zwrot.
        </p>
        <p>
          Przy outsourcingu klient nie musi martwić się rekrutacją, rotacją, absencjami, tempem pracy - nad tym wszystkim powinna czuwać firma zewnętrzna.
        </p>
        <p>
          W praktyce bardzo często na rynku można zaobserwować pewnego rodzaju hybrydę obu rozwiązań. Niby mamy podpisaną umowę outsourcingową, ale to klient decyduje ilu pracowników i na kiedy potrzebuje. To klient ustala ich wynagrodzenie, termin wypłaty, premie za efektywność i grafik pracy. To klient nimi kieruje i zarządza. To Klient przeprowadza szkolenia BHP, stanowiskowe, a często też zapewnia odzież. No i rozlicza się za każdą przepracowaną przez pracowników godzinę. Nie ma też mowy o żadnym SLA (Service Level Agreement), bo właściwie mamy do czynienia z pracą tymczasową, a nie outsourcingiem.
        </p>
        <h3>Zalety outsourcingu (tego faktycznego, a nie pozornego)</h3>
        <ul>
          <li>uwolnienie własnych zasobów i oszczędność czasu</li>
          <li>łatwiejsze planowanie kosztów</li>
          <li>optymalizacja budżetu (rozliczenie per sztuka pozwala uniknąć kosztów związanych z nieefektywną pracą czy odpadami i błędami w procesach)</li>
          <li>brak konieczności zarządzania zasobami ludzkimi</li>
          <li>szansa na wzrost wydajności efektywności outsourcowanych procesów</li>
          <li>odpowiedzialność agencji ograniczona jedynie zapisami umowy</li>
        </ul>
        <h3>Wady outsourcingu</h3>
        <ul>
          <li>ryzyko obniżenia jakości (zły wybór dostawcy)</li>
          <li>mniejsze bezpieczeństwo danych poufnych i tajemnicy przedsiębiorstwa (nawet najlepsze NDA nie chronią przed kopiowaniem pewnych rozwiązań)</li>
          <li>częściowa utrata kontroli nad wycinkiem biznesu i konieczność polegania na dostawcy</li>
          <li>brak możliwości wpływu na decyzje kadrowe i działania dostawcy, co może niekorzystnie odbić się na EB</li>
        </ul>
        <p>
          Dobrze zrobiony outsourcing, rzetelnie, zgodnie z przepisami i z dobrym dostawcą, może przynieść wiele korzyści firmie. Powierzenie części procesów komuś, kto się na tym zna (niekoniecznie agencji pracy), wie jak zarządzać procesami, jak je optymalizować i dostosowywać do nich politykę zatrudnienia, może pomóc w osiągnięciu celów strategicznych i zwiększeniu konkurencyjności.
        </p>
        <p>
          Z drugiej strony, pozorny outsourcing, a więc świadczenie usług pracy tymczasowej bez nazwania ich pracą tymczasową może skończyć się mandatem nawet do 100 000 zł, a do tego jeszcze możemy spodziewać się wizyty uśmiechniętego Pana z fiskusa, który poprosi o zwrot odpisanego podatku VAT.
        </p>
        <h2>Po co więc ubiera się pracę tymczasową w buty outsourcingu?</h2>
        <p><span className='sub-capture-light'>Po pierwsze -</span> <span className='sub-capture'>CZAS PRACY</span></p>
        <p>
          Pracownik tymczasowy nie może być zatrudniony u jednego pracodawcy użytkownika dłużej niż 18 miesięcy (z małymi wyjątkami). Jeśli więc nie chcesz przejąć pracownika na etat albo Twój projekt trwa dłużej, to .. sięgasz po outsourcing.
        </p>
        <p><span className='sub-capture-light'>Po drugie -</span> <span className='sub-capture'>ODPOWIEDZIALNOŚĆ</span></p>
        <p>
          W przypadku szkody wyrządzonej przez pracownika tymczasowego agencja jest zobowiązana do jej naprawy na zasadach na zasadach i w granicach obowiązujących pracownika zgodnie z przepisami o odpowiedzialności materialnej pracowników (czyli maksymalnie do trzykrotności wynagrodzenia pracownika). Przy umowie outsourcingowej agencja odpowiada za powierzone jej mienie i wszelkie zniszczenia dokonane przez swoich pracowników. Nie ma tu mowy o ograniczeniach, ponieważ w ustalaniu wysokości szkody należy powoływać się na Kodeks Cywilny a nie Kodeks Pracy.
        </p>
        <p><span className='sub-capture-light'>Po trzecie -</span> <span className='sub-capture'>ZWIĄZKI ZAWODOWE</span></p>
        <p>
          Jeśli w firmie funkcjonują związki zawodowe, to trzeba pamiętać, że chęć uzupełnienia wakatów za pośrednictwem pracowników tymczasowych musi zostać skonsultowana z organizacją związkową. Jeśli okres pracy pracowników tymczasowych wynosi mniej niż 6 miesięcy wystarczy poinformować związki zawodowe o zamiarze wprowadzanie pracowników tymczasowych na ten czas. Jeśli jednak planowane jest dłuższe zatrudnienia, to konieczne jest uzgodnienie tego kroku z organizacją związkową. Z tego powodu pracodawcy często decydują się na outsourcing usług, którego nie trzeba omawiać, zgłaszać czy uzyskiwać zgód zgromadzeń reprezentujących pracowników.
        </p>
        <p><span className='sub-capture-light'>Po czwarte -</span> <span className='sub-capture'>CENA, ale czy na pewno?</span></p>
        <p>
          Bardzo często mówi się, że głównym kryterium, dla którego firmy decydują się na outsourcing, zamiast pracy tymczasowej jest cena. Może faktycznie, w przypadku pozorowanego outsourcingu tak jest. Dzięki temu pracodawcy omijają zapis ustawy o pracownikach tymczasowych, który mówi, że nie mogą być oni zatrudniani na warunkach mniej korzystnych niż osoby pracujące na tych samych lub zbliżonych stanowiskach u Pracodawcy Użytkownika. W skrócie – nie mogą zarabiać mniej.
        </p>
        <p>
          <span className='ale'>ALE:</span> tylko wtedy jeśli pracują na podstawie umowy o pracę tymczasową. A ustawa dopuszcza przecież zatrudnienie w oparciu o umowę cywilnoprawną (umowę zlecenia). I oczywiście, można się obawiać tego, że PIP stwierdzi, że umowa zlecenia nosi znamiona stosunku pracy, tyle, że nie może sam z siebie nakazać zmiany charakteru umowy… W tym celu konieczne jest zgłoszenie sprawy do sądu i przygotowanie się na dość długą rozprawę sądową z całkiem nieźle opłacanymi prawnikami agencji.
        </p>
        <p><span className='sub-capture-light'>Po piąte -</span> <span className='sub-capture'>STRACH PRZED UTRATĄ KONTROLI</span></p>
        <p>
          Z jednej strony pracodawcy chcieliby, żeby agencja ponosiła pełną odpowiedzialność za szkody wyrządzone przez pracowników, żeby pracownicy mogli pracować dłużej niż 18-miesięcy i nie trzeba było powiadamiać związków zawodowych, z drugiej jednak nie chcą stracić kontroli nad pracownikami. Nie są gotowi na wydzielenie kawałka biznesu i oddanie go w ręce agencji, bo boją się, że zły wybór dostawcy będzie kosztował ich znacznie więcej niż kary z PIP.
        </p>
        <p>
          Finalnie wiele firm decyduje się na hybrydę między pracą tymczasową a outsourcingiem nie wiele zyskując i nie będąc w pełni świadomym, że pozorny outsourcing, to nie tylko widmo kary z PIP czy fiskusa, ale też prawdopodobna nagonka medialna, która odbije się czkawką i wyhamuje procesy rekrutacyjne w kolejnych latach.
        </p>
      </div>
    </div>
  );
}

export default ForShortPost3