import React from 'react';
import './ForShortPost5.scss';
import arrowLeft from '../../../assets/img/arrow-left.svg';
import { HashLink } from 'react-router-hash-link';

function ForShortPost5() {

  return (
    <div className="post5">
      <div className="post-hero">
        <div className="post5-capture">
          Kompendium wiedzy HR. Praca tymczasowa i outsourcing. Różnice, wady i zalety.
        </div>
      </div>
      <div className="post5-content-wrapper">
        <div className="post-navigate">
          <HashLink to={'/#porady'} smooth>
            <div className="back-to-main">
              <div className="arrow"><img src={arrowLeft} alt="" /></div>
              <div className="back">
                Back to articles
              </div>
            </div>
          </HashLink>
          <div className="post-date">
            
          </div>
        </div>
        <p>
          Wiele przedsiębiorstw, stojąc przed wyborem dostawcy rozwiązań personalnych, zastanawia się, którą z usług oferowanych przez agencje pracy tymczasowej wybrać. Outsourcing procesów czy pracę tymczasową? Co się bardziej opłaca? Jakie są wady i zalety obu rozwiązań? W tym materiale postaramy się przybliżyć obie formy współpracy. Przedstawić różnice między nimi oraz ich wady i zalety.
        </p>
        <h3>Kompendium wiedzy HR. Praca tymczasowa i outsourcing. Różnice, wady i zalety.</h3>
        <ul>
          <li style={{ fontSize: '20px' }}>Ustawa o zatrudnieniu pracowników tymczasowych. Na czym polega leasing pracowniczy?</li>
          <li style={{ fontSize: '20px' }}>Outsourcing. Na czym polega usługa outsourcingu procesów?</li>
          <li style={{ fontSize: '20px' }}>Zalety i wady outsourcingu procesów biznesowych (BPO)</li>
          <li style={{ fontSize: '20px' }}>Outsourcing i praca tymczasowa</li>
          <li style={{ fontSize: '20px' }}>Podsumowanie</li>
        </ul>
        <h2>Ustawa o zatrudnieniu pracowników tymczasowych. Na czym polega leasing pracowniczy?</h2>
        <p>
          Leasing pracowników to rozwiązanie, które pomaga uzupełnić braki kadrowe w stosunkowo krótkim czasie. Polega on na wynajęciu pracowników z innej firmy lub agencji pracy tymczasowej na określony czas. W przypadku wypożyczenia kadry od przedsiębiorstwa nie będącego agencją zatrudnienia, zasady współpracy reguluje art. 1741 Kodeksu Pracy. Z tej formy leasingu korzysta się jednak rzadko, zwykle wtedy, kiedy firmy mają powiązania kapitałowe.
        </p>
        <p>
          Innym rozwiązaniem, znacznie bardziej popularnym, jest wypożyczenie pracowników z agencji pracy. Zasady współpracy między podmiotami, a także prawa i obowiązki pracodawcy użytkownika oraz agencji pracy, uregulowane są ustawą z dnia 09 lipca 2003 roku o zatrudnianiu pracowników tymczasowych.
        </p>
        <h3>Jak działa praca tymczasowa?</h3>
        <p>
          Jeśli potrzebujesz dodatkowych pracowników na czas peaku albo chcesz uelastycznić strukturę kadrową, to warto zastanowić się nad wprowadzeniem pracowników tymczasowych do Twojej firmy. Dzięki współpracy z agencją pracy tymczasowej znaczna część obowiązków związanych z rekrutacją i zatrudnieniem pracowników zostaje scedowana na zewnętrznego dostawcę rozwiązań personalnych.
        </p>
        <p>
          To agencja pracy tymczasowej odpowiada za:
        </p>
        <ul>
          <li>rekrutację pracowników zgodnie z wytycznymi stanowiskowymi</li>
          <li>zatrudnienie pracowników oraz prowadzenie ich teczek osobowych</li>
          <li>rozliczenie czasu pracy oraz naliczenie i wypłatę wynagrodzeń</li>
          <li>odprowadzenie składek na ubezpieczenia społeczne</li>
          <li>legalizację pracy w przypadku zatrudnienia obcokrajowców</li>
        </ul>
        <p>
          Korzystając z usługi pracy tymczasowej, jako tzw. Pracodawca Użytkownik zyskujesz pracowników gotowych do pracy przy minimum formalności. Twoim zadaniem jest zapewnienie im bezpiecznych warunków pracy oraz kierowanie nimi i nadzorowanie wykonywanych przez nich zadań.
        </p>
        <h3>Zalety i wady pracy tymczasowej</h3>
        <p>
          Do głównych zalet współpracy w modelu leasingu pracowniczego (pracy tymczasowej) zalicza się:
        </p>
        <ul>
          <li>szybką rekrutację nawet dużych wolumenów pracowników, dzięki czemu przedsiębiorstwo ma możliwość utrzymania ciągłości pracy i realizacji dodatkowych zamówień</li>
          <li>minimum formalności ze strony Pracodawcy Użytkownika. Znaczna część obowiązków przeniesiona jest na agencję pracy, dzięki czemu wewnętrzne struktury przedsiębiorcy nie są obciążone dodatkowymi zadaniami</li>
          <li>wysoką elastyczność zatrudnienia, wynikającą m.in. z krótszych terminów wypowiedzenia umowy tymczasowej, co daje pracodawcom możliwość dostosowania wielkości zatrudnienia do zmieniającej się sytuacji rynkowej</li>
          <li>pozyskiwanie pracowników poza lokalnym rynkiem pracy. Agencje coraz częściej oferują przedsiębiorstwom możliwość zatrudnienia obcokrajowców, dzięki czemu pracodawcy nie są zmuszeni polegać jedynie na zasobach lokalnego rynku pracy</li>
          <li>proste rozliczenie z agencją zatrudnienia na podstawie faktury VAT</li>
        </ul>
        <p>
          Wśród wad korzystania z leasingu pracowniczego najczęściej wymienia się:
        </p>
        <ul>
          <li>stosunkowo krótki czas zatrudnienia pracowników tymczasowych - maksymalnie 18 miesięcy (w niektórych przypadkach do 36 miesięcy) w ciągu kolejnych 36 miesięcy</li>
          <li>niskie poczucie przynależności pracowników tymczasowych do miejsca pracy, co może prowadzić do wzrostu rotacji</li>
        </ul>
        <h2>Outsourcing. Na czym polega usługa outsourcingu procesów?</h2>
        <p>
          Outsourcing jest jednym z najszybciej rozwijających się rozwiązań z zakresu wsparcia biznesu. Coraz więcej przedsiębiorstw decyduje się na korzystanie z usług outsourcingu, aby szybciej rozwijać swoje kluczowe kompetencje, być bardziej konkurencyjnym na rynku oraz … ściągnąć sobie z barków olbrzymią część obowiązków związanych z obsługą procesów pobocznych.
        </p>
        <p>
          Słowo outsourcing to zlepek trzech angielskich słów: Outside Resource Using, co oznacza korzystanie z zasobów zewnętrznych. Polega on na wydzieleniu ze struktury firmy działów, zadań lub funkcji i przekazanie ich do realizacji zewnętrznemu podmiotowi. Zadania powierzone innej firmie w ramach outsourcingu nie są core bussinesem przedsiębiorstwa, ale ich efektywna realizacja może znacząco wpływać na bezpieczeństwo i pozycję konkurencyjną podmiotu. Outsourcing może dotyczyć np. obsługi kadrowo – płacowej, prawnej czy procesów IT.
        </p>
        <p>
          Agencje zatrudnienia świadczące usługi outsourcingu najczęściej realizują zadania związane z konkretnym elementem procesu logistycznego lub produkcyjnego, np. realizują obsługę zwrotów, są odpowiedzialne za pakowanie elementów lub zajmują się kontrolą jakości.
        </p>
        <h3>Przykład outsourcingu procesowego w E-commerce</h3>
        <p>
          Przedsiębiorstwo zdecydowało się powierzyć proces kompletowania zamówień zewnętrznemu dostawcy usług personalnych. W umowie outsourcingowej strony określiły kwotę za spakowanie konkretnego towaru, czas pakowania oraz odpowiedzialność za błędy procesowe. Insourcer, czyli podmiot zlecający obsługę na zewnątrz, rozlicza się za każdą sztukę skompletowanego towaru, jednak to po stronie firmy, która przyjęła zadanie do realizacji leży określenie liczby osób niezbędnych do terminowego i właściwego spakowania zamówień, określenie stawek wynagrodzenia dla zatrudnionych oraz nadzór i kontrola ich pracy.
        </p>
        <h2>Zalety i wady outsourcingu procesów biznesowych (BPO)</h2>
        <p>
          Zwolennicy usług outsourcingowych największe korzyści dla biznesu dostrzegają w:
        </p>
        <ul>
          <li>oszczędności czasu i możliwości skupienia się na rozwoju core bussinesu – powierzając zewnętrznemu podmiotowi część zadań pobocznych (np. konfekcjonowanie czy pakowanie), firma może skupić się na poprawie wskaźników w kluczowych obszarach. Uwalnia zasoby własne, dotychczas skupione na mniej istotnych, z punktu widzenia głównej działalności przedsiębiorstwa, procesach</li>
          <li>transparentny system rozliczeń i proste planowanie kosztów – rozliczając się za konkretną usługę (np. per sztuka spakowanego towaru) znacznie łatwiej planować budżet i uniknąć nieprzewidzianych kosztów związanych np. z nadgodzinami pracowników</li>
          <li>wzrost efektywności procesów – outsourcerowi zależy na wysokiej efektywności procesów. Rozliczenie za efekty pracy powoduje, że firmy zajmujące się obsługą outsourcingową stale badają, analizują i podejmują działania mające na celu uzyskanie jak najwyższych wskaźników efektywnościowych i jakościowych</li>
          <li>zapewnienie ciągłości produkcji – to outsourcer odpowiada za zabezpieczenie kadrowe procesów. W jego gestii jest rekrutacja i zatrudnienie pracowników odpowiedzialnych za realizację zadań. On też kontroluje wykonywanie obowiązków, dba o odpowiednią jakość i zarządza harmonogramem pracy</li>
        </ul>
        <p>
          Dla przeciwników tego rozwiązania najistotniejsza jest utrata pełnej kontroli nad outsourcingowanym procesem, ryzyko wybrania do współpracy nieodpowiedniego partnera i związany z tym spadek jakości oraz uzależnienie części procesów od zewnętrznego podmiotu.
        </p>
        <h2>
          Outsourcing i praca tymczasowa
        </h2>
        <p>
          Czy outsourcing i praca tymczasowa polega na tym samym? Zdecydowanie nie. Mimo iż w wielu źródłach można spotkać się z pojęciem outsourcingu pracowniczego, to warto pamiętać, że w przypadku wypożyczenia pracowników od innego podmiotu mamy do czynienia z usługą leasingu regulowaną przez art. 1741 KP lub usługą pracy tymczasowej, jeśli najem dotyczy pracowników agencji pracy. Outsourcing to zdecydowanie więcej niż praca tymczasowa. Wydzielenie obszaru i oddanie odpowiedzialności za jego efektywne funkcjonowanie zewnętrznemu podmiotowi oznacza, że przedsiębiorstwo ma możliwość określić parametry jakościowe i ilościowe, ale nie ma wpływu na sam sposób ich osiągania.
        </p>
        <h3>
          Różnice między pracą tymczasową a outsourcingiem
        </h3>
        <p>
          Różnic między outsourcingiem oraz pracą tymczasową jest wiele. Większość z nich wynika wprost z odrębnych regulacji prawnych, którym podlegają obie usługi.
        </p>
        <table>
          <tr>
            <th></th>
            <th>Praca tymczasowa</th>
            <th>Outsourcing</th>
          </tr>
          <tr>
            <td><b>Forma współpracy między Klientem a Agencją Zatrudnienia</b></td>
            <td>Umowa o świadczenie usług pracy tymczasowej</td>
            <td>Umowa outsourcingowa</td>
          </tr>
          <tr>
            <td><b>Regulacje prawne</b></td>
            <td>Ustawa o zatrudnianiu pracowników tymczasowych z 09 lipca 2003r.</td>
            <td>Kodeks Cywilny</td>
          </tr>
          <tr>
            <td><b>Model współpracy z pracownikami</b></td>
            <td>Trójstronny stosunek pracy (Agencja Pracy – Pracodawca Użytkownik – Pracownik Tymczasowy)</td>
            <td>Pełna odpowiedzialność za pracowników na outsourcerze</td>
          </tr>
          <tr>
            <td><b>Nadzór i kontrola pracy pracowników</b></td>
            <td>Pracodawca Użytkownik kieruje pracą i nadzoruje jej wykonanie</td>
            <td>Pełny nadzór i kontrola po stronie outsourcera</td>
          </tr>
          <tr>
            <td><b>Rodzaj wykonywanej pracy</b></td>
            <td>Ograniczenia wynikające z ustawy</td>
            <td>Brak ograniczeń</td>
          </tr>
          <tr>
            <td><b>Odpowiedzialność za sposób wykonywania pracy</b></td>
            <td>Po stronie Pracodawcy Użytkownika</td>
            <td>Po stronie outsourcera</td>
          </tr>
          <tr>
            <td><b>Wynagrodzenie pracowników</b></td>
            <td>Nie niższe niż oferowane pracownikom etatowym</td>
            <td>Określa outsourcer</td>
          </tr>
          <tr>
            <td><b>Maksymalny czas zatrudnienia</b></td>
            <td>18 miesięcy</td>
            <td>Nie dotyczy</td>
          </tr>
          <tr>
            <td><b>Rozliczenie z agencją</b></td>
            <td>Za godziny pracy</td>
            <td>Za efekty pracy</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
        <p style={{ fontStyle: 'italic' }}>Opracowanie własne</p>
        <h3>Podsumowanie</h3>
        <p>
          Bez względu na formę współpracy z agencją zatrudnienia - praca tymczasowa czy outsourcing – najważniejszą kwestią dla przedsiębiorstwa jest wybranie odpowiedniego partnera biznesowego do współpracy. Takiego, któremu warto zaufać. Za którym stoi doświadczenie i niezbędny know-how. Który dba o efektywność i jakość procesów. Na rynku usług tymczasowych funkcjonuje wiele podmiotów, które specjalizują się w różnych dziedzinach, branżach i usługach. Podpisanie umowy z jednym z nich warto poprzedzić dogłębnym research’em, spotkaniami z potencjalnymi kontrahentami oraz analizą porównawczą ofert.
        </p>
        <p style={{ fontStyle: 'italic' }}>
          Cykl <b>„Kompendium wiedzy HR. Współpraca z agencjami pracy tymczasowej” </b> to know – how w pigułce dla każdego specjalisty i managera, dla którego partnerstwo między HRem a biznesem ma strategiczne znaczenie. To źródło wiedzy dla kierowników i dyrektorów firm logistycznych oraz produkcyjnych, którzy chcą usprawnić procesy i zyskać efektywny zespół.
        </p>
        <p>
          Jeśli masz dodatkowe pytania dotyczące pracy tymczasowej lub outsourcingu kliknij w link poniżej i zadaj je naszemu ekspertowi. Możesz też śledzić kolejne wpisy w naszym biuletynie, w których wyjaśniamy kluczowe pojęcia związane z rynkiem usług agencji pracy.
        </p>
      </div>
    </div>
  );
}

export default ForShortPost5