import React from "react";
import './Success.scss';
import succesImg from '../../assets/img/sussess-label.png';


const Success = ({ t, active, setActive, children }) => {

    return (
        <div className="success-main-wrapper">
            <div className="success-content">
                <div className="success-image">
                    <img src={succesImg} alt="success" />
                </div>
                <div className="success-message-header">
                    Dziękuemy
                    za zgłoszenie
                </div>
                <div className="success-message-description">
                    Skontaktujemy się z Tobą wkrótce
                </div>
            </div>
        </div>
    );
}


export default Success;