import React from 'react';
import './ContactUs.scss';
import callAgent1 from '../../assets/img/agent1.png';
import callAgent2 from '../../assets/img/agent2.png';

function ContactUs() {

  const callAgentsData = [
    {
      img: callAgent1,

      role: 'członek zarządu',
      fullName: 'Agata Moroz',
      phone: '+48 508 040 154',
      email: 'agata.moroz@polskioutsourcing.pl'
    },
    {
      img: callAgent2,
      role: 'członek zarządu',
      fullName: 'Anna Ligięza',
      phone: '+48 508 040 153',
      email: 'anna.ligieza@polskioutsourcing.pl'
    },
  ];

  const callAgentItem = callAgentsData.map((item) => (
    <div key={item.phone} className="agent-card">
      <div className="agent-content-wrapper">
        <div className="agent-content">

          <div className="agent-img">
            <img src={item.img} alt="callAgent" />
          </div>

          <div className="agent-info">
            <div className="full-name">
              {item.fullName}
            </div>
            <div className="role">
              {item.role}
            </div>
            <div className="phone">
            <a href={`tel:${item.phone}`}>{item.phone}</a>
            </div>
            <div className="email">
            <a href={`mailto:${item.email}`}>{item.email}</a>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  ));

  return (
    <div id='kontakt' className="contact-us-main">
      <div className="head-line-content">
        <div className="head-line-capture">
          Kontakt
        </div>
        <div className="agent-card-wrapper">
          {callAgentItem}
        </div>
      </div>
    </div>
  );
}

export default ContactUs;