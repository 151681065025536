import React from 'react';
import './Advantages.scss';
import adImage1 from '../../assets/img/ad1.svg';
import adImage2 from '../../assets/img/ad2.svg';
import adImage3 from '../../assets/img/ad3.svg';
import adImage4 from '../../assets/img/ad4.svg';
import adImage5 from '../../assets/img/ad5.svg';
import adImage6 from '../../assets/img/ad6.svg';

function Advantages() {

  const advantagesCardData = [
    {
      img: adImage1,
      capture: 'Rozwój biznesu',
      description: 'Szybki dostęp do firm z branży i nie tylko. Działamy na rzecz rozwoju sektora outsourcingu w Polsce, członkonstwo to wzrost możliwości biznesowych i nowych inwestycji.'
    },
    {
      img: adImage2,
      capture: 'Dostęp do wiedzy i eksperckiej wiedzy',
      description: 'Nasze stowarzyszenie udostępnia swoim członkom ekspercką wiedzę, badania rynku i najlepsze praktyki.'
    },
    {
      img: adImage3,
      capture: 'Szkolenia i rozwój kompetencji',
      description: 'Szkolimy i prowadzimy programy rozwoju kompetencji, to pomaga w podnoszeniu kwalifikacji pracowników i efektywności firmy.'
    },
    {
      img: adImage4,
      capture: 'Wsparcie prawne i regulacyjne',
      description: 'Członkowie mają dostęp do porad prawników i ekspertów regulacyjnych, co jest szczególnie ważne w dynamicznie zmieniającym się środowisku prawnym.'
    },
    {
      img: adImage5,
      capture: 'Reprezentacja branży',
      description: 'Działamy, jako głos branży w kontaktach z rządem, organizacjami międzynarodowymi i innymi instytucjami, co pomaga w tworzeniu korzystnych warunków biznesowych.'
    },
    {
      img: adImage6,
      capture: 'Promocja firmy',
      description: 'Przynależność do stowarzyszenia zwiększa widoczność naszych członków i jej wiarygodność na rynku outsourcingu.'
    },
  ];

  const advantagesCardItem = advantagesCardData.map((item,index) => (
    <div key={index} className="advantages-card">
      <div className="advantages-card-content">
        <div className="advatnages-card-img">
          <img src={item.img} alt="icon" />
        </div>
        <div className="advantages-card-capture">
          {item.capture}
        </div>
        <div className="advantages-card-description">
          {item.description}
        </div>
      </div>
    </div>
  ))

  return (
    <div id='Korzyści' className="advantages-main">
      <div className="advantages-capture-block">
        <div className="advantage-small-capture">
        CZŁONKOSTWO
        </div>
        <div className="advantages-capture">
          KORZYŚCI Z CZŁONKOSTWA
        </div>
        <div className="advantages-description">
          Dołącz do wyjątkowej organizacji, którą jednoczy
          wspólna wizja zwiększania konkurencyjności sektora
          outsourcingowego oraz tworzącą unikalną ogólnopolską
          społeczność biznesową.
        </div>
      </div>
      <div className="advantages-cards-wrapper">
          {advantagesCardItem}
      </div>
    </div>
  );
}

export default Advantages;