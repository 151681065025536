import React from 'react';
import './ForShortPost5.scss';
import arrowLeft from '../../../assets/img/arrow-left.svg';
import { HashLink } from 'react-router-hash-link';

function ForShortPost5() {

  return (
    <div className="post5">
      <div className="post-hero">
        <div className="post5-capture">
        Już 17 maja w Gdańsku odbędzie się Ogólnopolska Konferencja Prawa Karnego Gospodarczego.
        </div>
      </div>
      <div className="post5-content-wrapper">
        <div className="post-navigate">
          <HashLink to={'/#porady'} smooth>
            <div className="back-to-main">
              <div className="arrow"><img src={arrowLeft} alt="" /></div>
              <div className="back">
                Back to articles
              </div>
            </div>
          </HashLink>
          <div className="post-date">
            
          </div>
        </div>
        <p>Już 17 maja w Gdańsku odbędzie się Ogólnopolska Konferencja Prawa Karnego Gospodarczego, której głównym partnerem jest Polskie Stowarzyszenie Outsourcingu. Konferencja ta to jeden dzień wymiany doświadczeń, debat i rozmów na temat przestępstwa oszustwa w obrocie gospodarczym z przedstawicielami Organów Ścigania, Prokuratury, Sądownictwa i Adwokatury oraz środowiska akademickiego. 
</p>
        </div>
    </div>
  );
}

export default ForShortPost5