import './CookiesPopUp.scss';
import React from 'react';
import close from '../../assets/img/close.png'

function CookiesPopUp(props) {

  return (
    <div className="cookies-main-wrapper">
    <div className="cookies-wrapper">
        <div className="cookies-content">
            <div className="cookies-text">
               Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie
            </div>
            <div className="cookies-buttons-wrapper">
                <div className="button-cookies-accept"  onClick={props.onClickPopUpAgreeButton}>
                   Zgoda
                </div>
                <div className='closePopup' onClick={props.onClickPopUpDenyButton}>
                    <img src={close} alt="close" />
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}

export default CookiesPopUp;