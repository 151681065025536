import React from 'react';
import './VebinarsBlog.scss';
import webinarsImg from '../../../assets/img/webinarsImg.png';
import blogShort6 from '../../../assets/img/bannerek_okpkg.png';
import blogShort7 from '../../../assets/img/webinarsImg.png';
import { HashLink } from 'react-router-hash-link';

function VebinarsBlog() {
  return (
    <div id='WydarzeniaIwebinary' className="Vebinars-blog-main">
      <div className="vebinars-sub-capture">
        news
      </div>
      <div className="vebinars-capture">
        Wydarzenia i webinary
      </div>

      <div className="vebinars-description-wrapper">
        <div className="vebinars-description-img">
          <img src={blogShort7} alt="webinars" />
        </div>
        <div className="vebinars-right-description">
          <div className="vebinars-date">
          21 maja
          </div>
          <div className="vebinars-descriptions-header">
          21 maja w Bydgoszczy odbędzie się Konferencja Biznes i Nauka.
          </div>
          <div className="vebinars-description">
          Kompetencje przyszłości w dobie AI, której głównym partnerem jest Polskie Stowarzyszenie Outsourcingu. To wydarzenie to przede wszystkim spotkania wiedzy naukowej, eksperckiej HR-owej i biznesowej wśród liderów firm i organizacji, naukowców i ekspertów, których głównym obszarem działania jest dział HR.
          </div>
          <div className='vebinars-button-wrapper' >
            <HashLink style={{ textDecoration: 'none', width: '188px' }} to={'/#form'} smooth>
              <div className="vebinars-red-button"> Zapisz się</div>
            </HashLink>
          </div>
        </div>
      </div>

      <div className="vebinars-description-wrapper">
        <div className="vebinars-description-img">
          <img src={blogShort6} alt="webinars" />
        </div>
        <div className="vebinars-right-description">
          <div className="vebinars-date">
            17 maja
          </div>
          <div className="vebinars-descriptions-header">
          Już 17 maja w Gdańsku odbędzie się Ogólnopolska Konferencja Prawa Karnego Gospodarczego.
          </div>
          <div className="vebinars-description">
          Której głównym partnerem jest Polskie Stowarzyszenie Outsourcingu. Konferencja ta to jeden dzień wymiany doświadczeń, debat i rozmów na temat przestępstwa oszustwa w obrocie gospodarczym z przedstawicielami Organów Ścigania, Prokuratury, Sądownictwa i Adwokatury oraz środowiska akademickiego. 
          </div>
          <div className='vebinars-button-wrapper' >
            <HashLink style={{ textDecoration: 'none', width: '188px' }} to={'/#form'} smooth>
              <div className="vebinars-red-button"> Zapisz się</div>
            </HashLink>
          </div>
        </div>
      </div>

      <div className="vebinars-description-wrapper">
        <div className="vebinars-description-img">
          <img src={webinarsImg} alt="webinars" />
        </div>
        <div className="vebinars-right-description">
          <div className="vebinars-date">
            Już wkrótce
          </div>
          <div className="vebinars-descriptions-header">
            Zapraszamy do śledzenia naszej strony internetowej, aby być na bieżąco z najnowszymi informacjami o naszych nadchodzących inicjatywach.
          </div>
          <div className="vebinars-description">
            Kliknij zapisz się, aby dostawać na mail bieżące informacje o nadchodzących wydarzeniach.
          </div>
          <div className='vebinars-button-wrapper' >
            <HashLink style={{ textDecoration: 'none', width: '188px' }} to={'/#form'} smooth>
              <div className="vebinars-red-button"> Zapisz się</div>
            </HashLink>
          </div>
        </div>
      </div>

    </div>
  );
}

export default VebinarsBlog