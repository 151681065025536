import React from 'react';
import './Partners.scss';
import './slick.css';
import './slick-theme.css';
import Slider from 'react-slick';
import partner1 from "../../assets/img/partner1.svg";
import partner2 from "../../assets/img/partner2.svg";
import partner3 from "../../assets/img/partner3.svg";
import partner4 from "../../assets/img/partner4.svg";
import partner5 from "../../assets/img/partner5.svg";
import partner6 from "../../assets/img/partner6.svg";
import partner7 from "../../assets/img/partner7.svg";
import partner8 from "../../assets/img/partner8.svg";
import partner9 from "../../assets/img/partner9.svg";
import partnerBottom1 from '../../assets/img/parntersBottom1.png';
import partnerBottom2 from '../../assets/img/parntersBottom2.png';
import { Link } from 'react-router-dom';

function Partners() {

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: 1026,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      },
    ]
  };

  const partnersData = [
    {
      img: partner1,
      link: 'https://www.plspoland.pl/'
    },
    {
      img: partner2,
      link: 'https://recruitment.personnelmedic.pl/'
    },
    {
      img: partner3,
      link: 'https://mpilichowski.wixsite.com/website'
    },
    {
      img: partner4,
      link: 'https://hrs-it.pl/'
    },
    {
      img: partner5,
      link: 'https://workport.pl/'
    },
    {
      img: partner6,
      link: 'https://www.innbenefits.pl/'
    },
    {
      img: partner7,
      link: 'https://personnelservice.pl/'
    },
    {
      img: partner8,
      link: 'https://personnelservice.pl/'
    },
    {
      img: partner9,
      link: 'https://personnelservice.pl/'
    },
  ];

  const partnersItem = partnersData.map((item, index) => (
    <div key={index}>
      <div  className="item">
        <div className="item-image-link">
          <Link to={`${item.link}`} target='_blank'><img src={item.img} alt="" /></Link>
        </div>
      </div>
    </div>
  ))

  return (
    <div id='сzłonkowie' className="partners-main">
      <div className="partners-text-block">
       
        <div className="partners-capture">
        CZŁONKOWIE
        </div>
        <div  className="partners-description">
          Z dumą przedstawiamy członków PSO – razem tworzymy społeczność, gdzie w centrach usług biznesowych w Polsce jest zatrudnionych ponad 20 000 osób.
        </div>
      </div>
      <div id='partnerzy' className="partners-carousel-vrapper">
        <Slider {...settings}>
          {partnersItem}
        </Slider>
      </div>
      <div className="partners-text-block">
        <div className="partners-top-capture">
        Partnerzy
        </div>
        <div className="partners-capture">
        PARTNERZY MERYTORYCZNI
        </div>
      </div>
      <div  className="partners-bottom">
   
          <div className="item">
            <div className="item-image-link">
              <Link to='https://hrnext.pl/' target='_blank'><img src={partnerBottom1} alt="" /></Link>
            </div>
          </div>
       
      
         {/* <div className="item">
            <div className="item-image-link">
              <Link to='https://pracodawcyrp.pl/' target='_blank'><img src={partnerBottom2} alt="" /></Link>
            </div>
          </div>
  */}
      </div>
    </div>
  );
}

export default Partners;